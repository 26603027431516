// Book mark pop up for iphone
$(document).ready(function() {
    //if(generic.cookie('bookmark_popup')!=1) {
    //    setTimeout('scrollTo(0,1)', 100); setTimeout('showBubble()', 500);
    //    generic.cookie('bookmark_popup', '1',{ expires: 7 });
    //}
});


var showBubble = function () {
   if (typeof google == "object" && typeof google.bookmarkbubble == "object") {
        var bubble = new google.bookmarkbubble.Bubble();
        bubble.iconUrl_ = "/apple-touch-icon.png";
        bubble.setHashParameter = function() {};
        bubble.hasHashParameter = function(){return false};
        bubble.TIME_UNTIL_AUTO_DESTRUCT = 5000;
        bubble.NUMBER_OF_TIMES_TO_DISMISS = 1;
        bubble.NUMBER_OF_TIMES_TO_DISAPPEAR = 2;
        bubble.showIfAllowed();
   }
}
